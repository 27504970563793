<template>
  <div class="mall">
    <div class="mall_content" :style="{'height': windowHeight - 50 + 'px'}">
      <Mall :configInfoData="configInfoData" :mallShopList="shopList" v-show="selectIndex == 0"/>
      <Service :configInfoData="configInfoData" v-show="selectIndex == 1"/>
      <Order v-show="selectIndex == 2"/>
    </div>
    <div class="tab_bar">
      <div class="handleBox" v-for="(item,index) in tabBarList" :key="index">
        <div class="menuBox" @click="goPages(index)">
          <div class="menuIcon">
            <img v-if="index != selectIndex" class="img" :src="item.iconPath"></img>
            <img v-else class="img" :src="item.selectIconPath"></img>
          </div>
          <div class="menuName">
            <div :class="index == selectIndex ? 'TextColor' : 'Text'">{{ item.tabbarName }}</div>
          </div>
        </div>
      </div>
    </div>
    <ul class="homeAction" v-if="actionData.length > 0 && selectIndex === 0">
      <li class="homeActionItem" v-if="actionData.includes('1')" @click="goLink('report')">
        <img src="../../assets/homeTS.png" alt="" class="homeActionIcon">
        投诉反馈
      </li>
      <li class="homeActionItem" v-if="actionData.includes('2')" @click="goPages(2)">
        <img src="../../assets/homeDD.png" alt="" class="homeActionIcon">
        我的订单
      </li>
      <li class="homeActionItem" v-if="actionData.includes('3')" @click="goPages(1)">
        <img src="../../assets/homeKF.png" alt="" class="homeActionIcon">
        联系客服
      </li>
    </ul>
  </div>
</template>

<script>
import Mall from "./index.vue";
import Service from "./service.vue";
import Order from "./order.vue";
import mall from '../../assets/mall.png';
import mallFill from '../../assets/mall-fill.png';
import customerService from '../../assets/customer-service.png';
import customerServiceFill from '../../assets/customer-service-fill.png';
import order from '../../assets/my-order.png';
import orderFill from '../../assets/my-order-fill.png';
import {getHomepage, getShopList} from "@/api/api";

let status = false;
export default {
  name: 'mall',
  components: {
    Mall,
    Service,
    Order,
  },
  data() {
    return {
      windowHeight: '',
      selectIndex: 0,
      tabBarList: [{
        "tabbarName": "商城", //tababr名称
        "iconPath": mall, //tabbar icon
        "selectIconPath": mallFill, //tabbar 选择icon
      },
        {
          "tabbarName": "客服", //tababr名称
          "iconPath": customerService, //tabbar icon
          "selectIconPath": customerServiceFill, //tabbar 选择icon
        },
        {
          "tabbarName": "订单", //tababr名称
          "iconPath": order, //tabbar icon
          "selectIconPath": orderFill, //tabbar 选择icon
        }],
      configInfoData: {},
      shopList: [],
      actionData: []
    }
  },
  mounted() {
    console.log('mounted');
    this.windowHeight = document.body.clientHeight;
    window.localStorage.setItem('windowHeight', this.windowHeight);
    if (status) {
      this.selectIndex = 2
    }
    if (this.$route.query.selectIndex) {
      this.selectIndex = this.$route.query.selectIndex
    }
    if (this.$route.params && this.$route.params.code) {
      console.log('有code', this.$route.params.code);
      window.localStorage.setItem('shopCode', this.$route.params.code);
    } else if (window.localStorage.getItem('shopCode')) {
      console.log('有缓存的code');
      window.localStorage.setItem('shopCode', window.localStorage.getItem('shopCode'));
    }
    this.getConfigInfo();
    this.getShopList();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == 'queryOrder') {
      status = true;
    } else {
      status = false;
    }
    next()
  },
  methods: {
    goLink() {
      this.$router.push({
        path: '/report',
      })
    },
    // 获取首页配置信息
    getConfigInfo() {
      let params = {
        shopCode: window.localStorage.getItem('shopCode'),
      }
      console.log('首页配置', params);
      getHomepage(params).then((res) => {
        console.log('获取首页配置的res', res)
        if (res.code == 200 || res.code == 0) {
          this.configInfoData = res.result;
          document.title = this.configInfoData.shopName
          this.actionData = res.result.imgSideFeatures;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // 获取商品列表
    getShopList() {
      let params = {
        shopCode: window.localStorage.getItem('shopCode'),
      }
      getShopList(params).then((res) => {
        console.log('商品列表的res', res)
        if (res.code == 200 || res.code == 0) {
          this.shopList = res.result;
        } else {
          this.$toast(res.message);
        }
      });
    },
    goPages(index) {
      this.selectIndex = index;
    },
  },
}
</script>

<style lang="less" scoped>
.mall {
  position: relative;
  .homeAction {
    position: fixed;
    right: 0;
    top: 40px;
    .homeActionItem {
      background-color: #000000;
      border-radius: 32px 0 0 32px;
      opacity: 0.8;
      margin-bottom: 15px;
      font-size: 16px;
      color: #fff;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      .homeActionIcon {
        width: 18px;
        height: 18px;
        padding-right: 4px;
      }
    }
  }
  .mall_content {
    background: #F8F8F8;
    overflow: auto;
    box-sizing: border-box;
  }

  .tab_bar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 50px;
    border-top: 1px solid #e9e9e9;
    background: #FFF;
    display: flex;
    z-index: 1000;

    .handleBox {
      height: 50px;
      display: flex;
      justify-content: center;

      .menuBox {
        width: 33vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .menuIcon {
          display: flex;
          justify-content: center;

          .img {
            width: 22px;
            height: 22px;
          }
        }

        .menuName {
          font-size: 12px;
          text-align: center;

          .TextColor {
            color: #F6555A;
          }

          .Text {
            color: #000;
          }
        }
      }
    }
  }
}
</style>
