<template>
    <div>
        <navTitle :title="title" :status="status"></navTitle>
        <div class="shop">
            <div class="shop_evaluate">
                <div class="evaluate">商户口碑</div>
                <div class="evaluate_number">{{ mallInfo.score }}</div>
                <span class="star">
                    <van-rate v-model="mallInfo.score" allow-half disabled disabled-color="#F6555A"/>
                </span>
            </div>
            <van-swipe class="swiper_box" :autoplay="3000" indicator-color="white" v-if="mallInfo.carousel.length">
                <van-swipe-item v-for="(item ,index) in mallInfo.carousel" :key="index">
                    <div class="swiper_item">
                        <img :src="item"></img>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <van-notice-bar left-icon="volume-o" :scrollable="false" style="height: 30px;border-radius: 5px;margin: 10px 0;">
                <van-swipe
                    vertical
                    class="notice-swipe"
                    :autoplay="3000"
                    :show-indicators="false"
                >
                    <van-swipe-item v-for="item in noticeData">{{ item }}</van-swipe-item>
                </van-swipe>
            </van-notice-bar>
            <!-- 列表式 -->
            <div class="shopList" v-for="(item, index) in shopList" @click="handleDetail(item.spuId, item.templateId)" v-if="mallInfo.shopTypeId == 1">
                <div class="shopList_content">
                    <div class="url">
                        <img :src="item.mainUrl"></img>
                        <div class="index">{{ index + 1 }}</div>
                    </div>
                    <div class="info">
                        <div class="info_top">
                            <div class="title">
                                <div class="title_image">
                                    <img src="../../assets/tuijian.png" v-show="item.cornerMark == 1"></img>
                                    <img src="../../assets/hot.png" v-show="item.cornerMark == 2"></img>
                                    <img src="../../assets/huobao.png" v-show="item.cornerMark == 3"></img>
                                </div>
                                <div class="title_text" :class="item.cornerMark != 0 ? 'title_text_active' : ''" v-show="item.spuTitle">{{ item.spuTitle }}</div>
                                <div class="spuTitle" v-show="!item.spuTitle && item.cornerMark != 0">{{ item.spuTitle }}</div>
                            </div>
                            <div class="intro">{{ item.spuDescription }}</div>
                        </div>
                        <div class="price_sales">
                            <div class="price">
                                <div class="price_left">
                                    ￥<span class="price_text">{{ item.sellingPrice }}</span>
                                </div>
                                <div class="price_right">秒杀价</div>
                            </div>
                            <div class="sales">销量 {{ item.salesVolume }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 卡片式 -->
            <div class="shopCard" v-if="mallInfo.shopTypeId == 2">
                <div class="shopCard_content" v-for="(item, index) in shopList" @click="handleDetail(item.spuId, item.templateId)">
                    <div class="url">
                        <img :src="item.mainUrl"></img>
                        <div class="index">{{ index + 1 }}</div>
                    </div>
                    <div class="title">
                        <div class="title_image" v-show="item.cornerMark != 0">
                            <img src="../../assets/tuijian.png" v-show="item.cornerMark == 1"></img>
                            <img src="../../assets/hot.png" v-show="item.cornerMark == 2"></img>
                            <img src="../../assets/huobao.png" v-show="item.cornerMark == 3"></img>
                        </div>
                        <div class="title_text" :class="item.cornerMark != 0 ? 'title_text_active' : ''" v-show="item.spuTitle">{{ item.spuTitle }}</div>
                        <div class="spuTitle" v-show="!item.spuTitle && item.cornerMark != 0">{{ item.spuTitle }}</div>
                    </div>
                    <div class="intro">{{ item.spuDescription }}</div>
                    <div class="bottom">
                        <div class="price">
                            <div class="price_content">￥<span class="price_text">{{ item.sellingPrice }}</span></div>
                            <div class="kill_price">秒杀价</div>
                        </div>
                        <div class="sales">销量 {{ item.salesVolume }}</div>
                    </div>
                </div>
            </div>
            <div class="no_data">已经到底了~</div>
            <div v-show="fullScreen">
                <div class="hot_sale" v-show="showHotStatus">
                    <div class="image">
                        <img src="../../assets/hot-sale.png"></img>
                    </div>
                    <span class="hot_sale_text">X {{randomNumber}} &nbsp;&nbsp; {{randomNumber}}位用户正在选购中···</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getShopList } from "@/api/api";
    import navTitle from "@/components/navTitle.vue";
    let timer = null;
    export default {
        components: {
            navTitle
        },
        props: {
			configInfoData: {
				type: Object,
				default: {},
			},
			mallShopList: {
				type: Array,
				default: [],
			},
		},
		watch:{
			configInfoData(newval){
				this.mallInfo = newval;
                this.title = this.mallInfo.shopName;
			},
            mallShopList(newval){
				this.shopList = newval;

            }
		},
        data() {
            return{
                title: '',
                status: 0,
                mallInfo: {
					score: 0,	// 评价
                    carousel: [],
					shopTypeId: '',
                    shopName: '',
				},
                noticeData: ['10秒前 ***6855 用户已下单', '10秒前 ***7633 用户已下单', '10秒前 ***1319 用户已下单', '10秒前 ***8863 用户已下单', '10秒前 ***6955 用户已下单', '10秒前 ***7231 用户已下单'],
				swiperList: [],
                shopList: [],
                fullScreen: false,
				randomNumber: null,
				showHotStatus: false,
            }
        },
        mounted(){
            this.getShopList()
            this.fullScreen = document.body.clientHeight > 600 ? true: false
			console.log('fullScreen', this.fullScreen);
            this.setShowHot()
        },
        methods: {
            // 详情
            handleDetail(id, templateId){
                console.log('templateId', templateId);
                let code = window.localStorage.getItem('shopCode');
                if(templateId == 1){
                    this.$router.push({
                        path: `/placeAnOrder/templateShop/${code}/${id}`
                    })
                } else if(templateId == 2){
                    this.$router.push({
                        path: `/phoneOrder/templateShop/${code}/${id}`
                    })
                }

            },
            // 获取商品列表
            getShopList(){
                let params = {
                    shopCode: window.localStorage.getItem('shopCode'),
                }
                getShopList(params).then((res) => {
					console.log('商品列表的res', res)
					if(res.code == 200 || res.code == 0){
						this.shopList = res.result;
					} else {
						this.$toast(res.message);
					}
				});
            },
            // 每三秒生成随机数进行热卖显示
			setShowHot () {
				if(timer){
					clearTimeout(timer)
				}
				timer = setInterval(() => {
					this.showHotStatus = !this.showHotStatus
					this.randomNumber = Math.floor(Math.random() * 100);
				}, 3000)
			},
        }
    }
</script>

<style lang="less" scoped>
.shop{
    width: 100vw;
    padding: 0 10px 10px;
    text-align: left;
    box-sizing: border-box;
    .shop_evaluate{
        display: flex;
        align-items: center;
        .evaluate{
            color: #999999;
            font-size: 12px;
            padding: 5px 10px;
            background: #E9EAF0;
            border-radius: 50px;
        }
        .evaluate_number{
            color: #F6555A;
            font-size: 14px;
            margin: 0 5px 0 10px;
        }
    }
    .swiper_box{
        width: 100%;
        height: calc((100vw - 20px) / 3);
        border-radius: 5px;
        margin: 10px 0;
        .swiper_item{
            width: 100%;
            height: calc((100vw - 20px) / 3);
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .notice-swipe {
        height: 30px;
        line-height: 30px;
    }
    .shopList{
        margin: 0 0 10px;
        background: #FFF;
        .shopList_content{
            padding: 10px;
            display: flex;
            .url{
                position: relative;
                width: calc((100vw - 40px) / 4 + 30px); //计算商品图片宽度 (总宽度 - 40px【外padding * 2 + 内padding *2】) /3
                height: calc((100vw - 40px) / 4 + 30px);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
                .index{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30px;
                    height: 20px;
                    color: #FFF;
                    font-size: 14px;
                    border-radius: 5px 0 5px 0;
                    background: #5c5c5c;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .info{
                width: calc((100vw - 40px) / 4 * 3 - 40px); // 计算商品信息宽度 商品图片宽度 * 2 - 10px【商品图片和商品信息的间距】
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .info_top{
                    .title{
                        position: relative;
                        display: flex;
                        .title_image{
                            position: absolute;
                            left: 0;
                            width: 52px;
                            height: 24px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .title_text{
                            font-weight: bold;
                            font-size: 14px;
                            color: #333333;
                            text-align: justify;
                            display: -webkit-box;
                            overflow: hidden;
                            word-break: break-all; /* break-all(允许在单词内换行。) */
                            text-overflow: ellipsis; /* 超出部分省略号 */
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1; /** 显示的行数 **/
                        }
                        .spuTitle{
                            height: 25px;
                            font-weight: bold;
                            font-size: 16px;
                            color: #333333;
                            text-align: justify;
                            display: -webkit-box;
                            overflow: hidden;
                            word-break: break-all; /* break-all(允许在单词内换行。) */
                            text-overflow: ellipsis; /* 超出部分省略号 */
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1; /** 显示的行数 **/
                        }
                        .title_text_active{
                            text-indent: 52px;
                            line-height: 22px;
                        }
                    }
                    .intro{
                        line-height: 18px;
                        font-size: 12px;
                        color: #AC6E34;
                        text-align: justify;
                        margin: 3px 0;
                        display: -webkit-box;
                        overflow: hidden;
                        word-break: break-all; /* break-all(允许在单词内换行。) */
                        text-overflow: ellipsis; /* 超出部分省略号 */
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /** 显示的行数 **/
                    }
                }

                .price_sales{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .price{
                        font-size: 12px;
                        color: #F6555A;
                        display: flex;
                        .price_left{
                            width: 50px;
                            .price_text{
                                font-weight: bold;
                                font-size: 20px;
                                color: #F6555A;
                            }
                        }
                        .price_right{
                            font-size: 12px;
                            padding: 0 5px;
                            margin-left: 5px;
                            border-radius: 5px;
                            border: 1px solid #F6555A;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .sales{
                        font-size: 14px;
                        color: #999999;
                    }
                }
            }
        }
    }
    .shopCard{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .shopCard_content{
            width: calc(100vw / 2 - 35px);
            padding: 10px;
            margin: 0 0 10px;
            background: #FFF;
            .url{
                position: relative;
                width: calc(100vw / 2 - 35px);
                height: calc(100vw / 2 - 35px);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
                .index{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30px;
                    height: 20px;
                    color: #FFF;
                    font-size: 14px;
                    border-radius: 5px 0 5px 0;
                    background: #5c5c5c;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .title{
                position: relative;
                margin-top: 10px;
                display: flex;
                .title_image{
                    position: absolute;
                    left: 0;
                    width: 52px;
                    height: 24px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .title_text{
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                    text-align: justify;
                    display: -webkit-box;
                    overflow: hidden;
                    word-break: break-all; /* break-all(允许在单词内换行。) */
                    text-overflow: ellipsis; /* 超出部分省略号 */
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1; /** 显示的行数 **/
                }
                .spuTitle{
                    height: 23px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                    text-align: justify;
                    display: -webkit-box;
                    overflow: hidden;
                    word-break: break-all; /* break-all(允许在单词内换行。) */
                    text-overflow: ellipsis; /* 超出部分省略号 */
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1; /** 显示的行数 **/
                }
                .title_text_active{
                    text-indent: 52px;
                    line-height: 22px;
                }
            }
            .intro{
                line-height: 18px;
                font-size: 12px;
                color: #AC6E34;
                text-align: justify;
                margin: 5px 0;
                display: -webkit-box;
                overflow: hidden;
                word-break: break-all; /* break-all(允许在单词内换行。) */
                text-overflow: ellipsis; /* 超出部分省略号 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /** 显示的行数 **/
            }
            .bottom{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price{
                    color: #F6555A;
                    font-size: 12px;
                    margin: 10px 0;
                    display: flex;
                    .price_content{
                        display: flex;
                        align-items: center;
                        .price_text{
                            font-weight: bold;
                            font-size: 20px;
                            color: #F6555A;
                        }
                    }
                    .kill_price{
                        font-size: 12px;
                        padding: 0 5px;
                        margin-left: 5px;
                        border-radius: 5px;
                        border: 1px solid #F6555A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .sales{
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
    .no_data{
        font-size: 16px;
        color: #999999;
        text-align: center;
        margin: 10px 0;
    }
    .hot_sale{
        position: fixed;
        left: 30px;
        bottom: 60px;
        width: calc(100vw - 60px);
        height: 55px;
        border-radius: 10px;
        background-color: rgba(255, 233, 166, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        .image{
            width: 54px;
            height: 88px;
            margin-bottom: 36px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .hot_sale_text{
            font-weight: bold;
            font-size: 16px;
            color: #F6555A;
            margin-left: 10px;
        }
    }
}
</style>
